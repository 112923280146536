

import { TurnedInTwoTone } from "@material-ui/icons";
import React, { createRef } from "react"
import { Button, Col, Container, Form, Modal, Nav, Navbar, Row } from "react-bootstrap";
import { CentralInput } from "../..";
import { Central, centralModal, ModalHelper } from "../../central/central";
import { FileManager } from "../../filemanager/filemanager";
import { cloneDeep } from "../../tools";


import './datalist.css'
import { PrintFrame } from "./printframe";


export interface DataListProps {
    hasChanged?: () => void;
}

export interface DataListState {
    elements: ListElement[]
}

export interface ListElement {
    id: number,
    weekStartDate: number
    weekEndDate: number,
    bill?: string,
    data: {
        entrys: {
            desc: string,
            value: string
        }[]
    }
}


function asDateString(dn: number) {
    let d = new Date(dn);
    return `${d.getDate()}.${d.getMonth() + 1} ${d.getFullYear()}`
}

function getMonthId(dn: number): number {
    let d = new Date(dn);
    return ((d.getFullYear()) * 100 + (d.getMonth() + 1));
}



function getWeekIdentificator(dn: number) {
    let d = new Date(dn)
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number Make
    // Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil(
        ((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
    );

    const weekStartDate = new Date(d.getTime());
    weekStartDate.setUTCDate(weekStartDate.getUTCDate() - 3);

    const weekEndDate = new Date(d.getTime());
    weekEndDate.setUTCDate(weekEndDate.getUTCDate() + 3);
    return {
        id: d.getUTCFullYear() * 100 + weekNo,
        weekStartDate,
        weekEndDate
    }
}

function flipDate(st: string) {
    let back = st.split('.').map(e => e.length == 1 ? '0' + e : e);
    return back[2] + '-' + back[1] + '-' + back[0] + 'T00:00:00'
}


export class DataList extends React.Component<DataListProps, DataListState> {

    constructor(p: DataListProps) {
        super(p);
        this.state = {
            elements: []
        }
    }

    getElements() {
        return cloneDeep(this.state.elements);
    }
    setElement(p: ListElement[]) {
        this.setState({ elements: cloneDeep(p) })
        this.prepareElements();
    }
    public async componentDidMount() {
    }

    prepareElements(next?: () => void) {
        this.setState(r => {
            let e = cloneDeep(r.elements);
            let d = Date.now();
            let ad = 1000 * 60 * 60 * 24;
            for (let i = d - (31 * ad); i < d + (31 * ad); i += ad) {
                let c = getWeekIdentificator(i);
                if (r.elements.filter(e => e.id == c.id).length == 0) {
                    r.elements.push({ id: c.id, data: { entrys: [] }, weekStartDate: c.weekStartDate.getTime(), weekEndDate: c.weekEndDate.getTime() })
                }
            }

            r.elements.map(e => {
                e.data.entrys = e.data.entrys.filter((e, ix, arr) => {
                    if (e.desc !== '' || e.value !== '') return true;
                    if (ix == arr.length - 2) return true;
                    return false;
                })
                if (!e.data.entrys[e.data.entrys.length - 1] || e.data.entrys[e.data.entrys.length - 1].desc !== '') {
                    e.data.entrys.push({ value: '', desc: '' })
                }
            })
            return {
                elements: r.elements
            }
        }, () => {
            if (next) next();
        })
    }


    assignBill = async (widx: number) => {
        let code: string = '';
        let bill: string = '';
        let sel = this.state.elements.filter(e => e.id == widx)[0];
        if (sel == undefined) return;
        let lines = [
            'Unterstützungsleistungen ' + asDateString(sel.weekStartDate) + ' - ' + asDateString(sel.weekEndDate),
            '',
        ];
        sel.data.entrys.filter(e => e.value !== '').map(e => {
            lines.push(e.desc + '\t\t' + e.value + ' PT')
        })
        code = lines.join('\r\n')
        let ass = await centralModal((sys: ModalHelper<{ code: string, bill: string }, 'ok' | 'cancel'>) => {
            return <>
                <Modal.Header closeButton>
                    <Modal.Title>Rechnung Zuweisen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Rechnungstexte</Form.Label>
                            <CentralInput type="textarea" ref={sys.inputRef('code')} defaultValue={code}></CentralInput>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>RechnungsID</Form.Label>
                            <CentralInput type="input" ref={sys.inputRef('bill')} defaultValue={bill}></CentralInput>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={sys.closeClick('cancel')}>
                        Abbrechen
                    </Button>
                    <Button onClick={sys.closeClick('ok')}>
                        Abschliessen
                    </Button>
                </Modal.Footer>
            </>
        });
        if (ass.result == 'ok') {
            if (ass.data.bill !== '') {
                if ((await centralModal(Central.YesNo('Dieser Vorgang ist entgültig!', 'Rechnungsnummer "' + ass.data.bill + '" zuweisen?'))).result == 'yes') {
                    this.setState(r => {
                        let n = cloneDeep(r.elements);
                        n.map(x => {
                            if (x.id == widx) {
                                x.bill = ass.data.bill
                            }
                        })
                        return {
                            elements: n
                        }
                    }, () => {
                        if (this.props.hasChanged) this.props.hasChanged();
                    })
                }
            }
        }
    }

    onChange = (widx: number, el: number, type: 'desc' | 'value', ev: React.ChangeEvent<HTMLInputElement>) => {
        let val = ev.currentTarget.value;
        this.setState(r => {
            let dx = cloneDeep(r.elements);
            dx.map(d => {
                if (d.id !== widx) return;
                if (el < d.data.entrys.length) {
                    if (type == 'desc') d.data.entrys[el].desc = val;
                    if (type == 'value') d.data.entrys[el].value = val;
                };
                d.data.entrys = d.data.entrys.filter((e, ix, arr) => {
                    if (e.desc !== '' || e.value !== '') return true;
                    if (ix == arr.length - 2) return true;
                    return false;
                })
                if (d.data.entrys.length == 0 || (d.data.entrys[d.data.entrys.length - 1].desc !== '' || d.data.entrys[d.data.entrys.length - 1].value !== '')) {
                    d.data.entrys.push({ desc: '', value: '' })
                }
            });

            return {
                elements: dx,
            }
        }, () => {
            if (this.props.hasChanged) this.props.hasChanged();
        })
    }

    import = async () => {
        let data = await centralModal(Central.AskText('', 'Daten', 'Importieren'));
        if (data.result == 'ok') {
            let dx = data.data.value.split(/[\r\n]+/).map(e => e.split('|'));
            this.setState(r => {
                let n = cloneDeep(r.elements);
                dx.map(e => {
                    let date = getWeekIdentificator((new Date(flipDate(e[0])).getTime()));
                    let desc = e[1];
                    let pt = parseFloat(e[2]);
                    console.log(date, desc, pt);
                    n.map(e => {
                        if (e.id == date.id) {
                            e.data.entrys.push({ desc, value: '' + pt })
                        }
                    })
                })
                return {
                    elements: n
                }
            }, () => this.prepareElements(() => {
                if (this.props.hasChanged) this.props.hasChanged();

            }))
        }
    }

    asValue(value: string, desc: string, cl: string = '') {
        return <div className={"valueDef " + cl}>
            <span className="value">{value}</span>
            <span className="desc">{desc}</span>
        </div>
    }

    render() {
        let years: {
            [yearid: string]: {
                sum: number,
                m: {
                    [monthid: string]: number
                }
            }
        } = {};
        return <>
            <Container className="hide_on_print">
                <Row>
                    <Col className="p-0">
                        <Container fluid>
                            {this.state.elements.map(e => <Row className={"mb-2 weekRow " + (getMonthId(e.weekStartDate) % 2 == 0 ? '' : 'otherMonth')} key={e.id}>
                                <Col className="dlTimeInfo" xs={2}>
                                    <span className="weekid">{e.id}</span>
                                    <span className="from">{asDateString(e.weekStartDate)}</span>
                                    <span className="to"> {asDateString(e.weekEndDate)}</span>
                                    {e.bill !== undefined ? <>
                                        <span className="bill"> {e.bill}</span>
                                    </> : null}
                                </Col>
                                <Col xs={10} className="p-0">
                                    <Container fluid >
                                        {e.data.entrys.map((y, ix) => <Row key={ix}>
                                            {
                                                e.bill !== undefined ? <>
                                                    <Col xs={10}>
                                                        <div>{y.desc}</div>
                                                    </Col>
                                                    <Col xs={2} className="p-0 pr-2" >
                                                        <div>{y.value}</div>
                                                    </Col>
                                                </> : <>
                                                        <Col xs={10}>
                                                            <Form.Control as="input" onChange={this.onChange.bind(null, e.id, ix, 'desc')} value={y.desc} ></Form.Control>
                                                        </Col>
                                                        <Col xs={2} className="p-0 pr-2" >
                                                            <Form.Control as="input" onChange={this.onChange.bind(null, e.id, ix, 'value')} value={y.value} ></Form.Control>
                                                        </Col>
                                                    </>
                                            }
                                        </Row>)}
                                        <Row>
                                            <Col xs={12}>
                                                {(() => {
                                                    let weekTime = e.data.entrys.reduce((p, c) => c.value !== '' ? p + parseFloat(c.value) : p, 0);
                                                    let mid = '' + getMonthId(e.weekStartDate);
                                                    let yearid = '' + (new Date(e.weekStartDate)).getFullYear();
                                                    if (years[yearid] == undefined) years[yearid] = { sum: 0, m: {} };
                                                    years[yearid].sum += weekTime;
                                                    if (years[yearid].m[mid] == undefined) years[yearid].m[mid] = 0;
                                                    years[yearid].m[mid] += weekTime;
                                                    let price = years[yearid].sum / Object.keys(years[yearid].m).length;
                                                    let pp = Math.round(price * 900 * 10) / 10;
                                                    let py = Math.round(years[yearid].sum * 900 * 10) / 10;
                                                    return <>
                                                        {this.asValue('' + weekTime, 'd this Week')}
                                                        {this.asValue('' + years[yearid].m[mid], 'd this Month')}
                                                        {this.asValue('' + py, '€ this Year')}
                                                        {this.asValue('' + pp, '€ per Month')}
                                                    </>
                                                })()}
                                                {e.bill == undefined ? <>
                                                    <Button className="mt-2 float-right" onClick={this.assignBill.bind(null, e.id)} variant="primary" size="sm">Assign Bill</Button>
                                                </> : null}

                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>)}
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    }
} 