import * as React from "react";

export interface CentralInputProps {
    defaultValue?: string
    type?: 'input'|'select'|'textarea',
    as?: 'password',
    map? : {value: string, text?: string}[],
    onKeyPress?: (event: React.KeyboardEvent<any>) => void
    onValueChange?: (value: string) => void,
}

export interface CentralInputState {
    value: string,
    type: 'input'|'select'|'textarea',
    map: {value: string, text?: string}[],
}


export class CentralInput extends React.Component<CentralInputProps,CentralInputState> {
    private ref  = React.createRef<HTMLInputElement>();
    private refSel  = React.createRef<HTMLSelectElement>();
    private refArea  = React.createRef<HTMLTextAreaElement>();
    constructor (p: CentralInputProps) {
        super(p)
        this.state = {
            type: p.type == undefined ? 'input' : p.type,
            value: p.defaultValue ? p.defaultValue : '',
            map: p.map == undefined ? [] : p.map,
        }
    }
    getCurrentValue () {
        return this.state.value
    }
    getRefCurrent () : HTMLTextAreaElement| HTMLInputElement|HTMLSelectElement|null {
        if (this.state.type == 'input') {
            return this.ref.current
        }
        if (this.state.type == 'select') {
            return this.refSel.current
        }
        if (this.state.type == 'textarea') {
            return this.refArea.current
        }
        return null
    }
    setFocus () {
        if (this.ref.current) {
            this.ref.current.focus();
            this.ref.current.setSelectionRange(0, this.ref.current.value.length);
            return;
        }
        if (this.refArea.current) {
            this.refArea.current.focus();
            this.refArea.current.setSelectionRange(0, this.refArea.current.value.length);
            return;
        }
        if (this.refSel.current) {
            this.refSel.current.focus();
            return;
        }
    }
    change (event: React.ChangeEvent<HTMLInputElement>) {
        let value = event.currentTarget.value
        this.setState({value}, () => {
            if (this.props.onValueChange) this.props.onValueChange(value)
        })
    }
    changeSel (event: React.ChangeEvent<HTMLSelectElement>) {
        let value = event.currentTarget.value
        this.setState({value}, () => {
            if (this.props.onValueChange) this.props.onValueChange(value)
        })
    }
    changeArea (event: React.ChangeEvent<HTMLTextAreaElement>) {
        let value = event.currentTarget.value
        this.setState({value}, () => {
            if (this.props.onValueChange) this.props.onValueChange(value)
        })
    }
    setCurrentValue (value: string) {
        this.setState({value}, () => {
        })
    }
    render () {
        if (this.state.type == 'input') {
            return <input className="form-control" type={this.props.as == 'password' ? 'password': 'text'} ref={this.ref} value={this.state.value} onChange={this.change.bind(this)}></input>
        }
        if (this.state.type == 'textarea') {
            return <textarea rows={3} className="form-control" ref={this.refArea} value={this.state.value} onChange={this.changeArea.bind(this)}></textarea>
        }
        if (this.state.type == 'select') {
            return <select onKeyPress={this.props.onKeyPress} className="form-control" ref={this.refSel}  value={this.state.value} onChange={this.changeSel.bind(this)}>
                {this.state.map.map((e,ix) => <option key={ix} value={e.value}>{e.text ? e.text : e.value}</option>)}
            </select>
        }
        return <div>Render Error on Central Input Control Type </div>
    }
}