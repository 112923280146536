import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Central, CentralInput, centralModal, ModalHelper } from "./lib";


export class CentralDemo extends React.Component {
    constructor(p: any) {
        super(p);
        this.state = {
        };
    }
    simple = async ()  => {
        let step = async (step: string) =>  centralModal(Central.Info(step, 'Step', 'Ok'));
        await centralModal(Central.Info('Eine einfache Box', 'Info', 'weiter'));
        let answer = await centralModal(Central.YesNo('Wollen Sie fortsetzen?', 'Weiter?', {textYes: 'Ja!'}))
        if (answer.result == 'yes') {
            await step('Sie haben Ja gedrückt');
            await step('Ok, auf zu einer Komplexen InputBox');
            let answer = await centralModal((sys: ModalHelper<{name: string}, 'ok' | 'cancel'>) => {
                return <>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>Eingabe von Text</Form.Label>
                                <CentralInput ref={sys.inputRef('name')} defaultValue={''}></CentralInput>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={sys.closeClick('cancel')}>
                            Abbrechen
                        </Button>
                        <Button variant="primary" onClick={sys.closeClick('ok')}>
                            Speichern
                        </Button>
                    </Modal.Footer>
                </>;
            });
            if (answer.result == 'ok') {
                await step ('Sie haben '+JSON.stringify(answer.data)+' angegeben');
            } else {
                await step ('Der dialog wurde abgebrochen');
            }
        } else {
            await step('Auf Wiedersehen')
        }
    }
    render() {
        return <>
            <h1>Beispiele für MessageBoxen</h1>
            <Button onClick={this.simple}>SimpleBox</Button>
        </>;
    }
}
