import { Button } from "@material-ui/core";
import React, { createRef } from "react";
import { Card, Form } from "react-bootstrap";
import { Central, centralModal, rArray } from "./lib";
import { HomePage } from "./lib/homepage/homepage";


// export class DemoMain extends React.Component<{ hp: HomePageElementTools }> {
//     private blocked?: () => void;
//     constructor(p: { hp: HomePageElementTools }) {
//         super(p);
//         this.lock();
//     }
//     lock = () => {
//         if (this.blocked) this.blocked();
//         this.blocked = this.props.hp.block();
//     }
//     save = () => {
//         if (this.blocked) this.blocked();
//         this.blocked = undefined;
//     }
//     render() {
//         return <Card>
//             <Card.Body>
//                 <Form.Control type="text"></Form.Control>
//                 <Button onClick={this.save}>Test für Done</Button>
//             </Card.Body>
//         </Card>

//     }
// }


export interface State {
    users: string[],
}

export class HomePageDemo extends React.Component<{}, State> {
    homepage = createRef<HomePage<any>>();
    constructor(p: any) {
        super(p);
        this.state = {
            users: ['klaus'],
        };
    }

    render() {
        return <>
            <HomePage<'userlist' | 'homepageside'> ref={this.homepage}
                side={{
                    id: 'root',
                    title: 'Home',
                    content: 'homepageside',
                    subPages: [
                        {
                            id: 'userman',
                            title: 'UserManager',
                            content: 'userlist',
                            subPages: [
                                {
                                    id: 'u_adduser',
                                    title: 'Hinzufügen',
                                    desc: 'eines neuen Nutzers',
                                    click: async () => {
                                        let nn = await centralModal(Central.Ask('', 'Neuer Name'));
                                        if (nn.result == 'ok') {
                                            this.setState(r => {
                                                return {
                                                    users: rArray(r.users).add(nn.data.value)
                                                }
                                            });
                                            this.homepage.current!.setBlock(true,'Bitte erst speichern');
                                        }
                                    }
                                },
                                {
                                    id: 'u_export',
                                    title: 'Speichern',
                                    desc: 'aller NutzerDaten',
                                    click: () => {
                                        this.homepage.current!.setBlock(false)
                                    }
                                }
                            ]
                        }
                    ]
                }}
                >
                {{
                    userlist: <div>
                        {this.state.users.map((e, ix) => <div key={ix}>{e}</div>)}
                    </div>,
                    homepageside: <p>
                        Willkommen ... {this.state.users.join(',')}
                    </p>

                }}
            </HomePage>

        </>;
    }
}
