import { Person, ThreeSixty } from "@material-ui/icons";
import React from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { Central, CentralInput, centralModal, ModalHelper } from "./lib";
import { DataBox, DataBoxWhats } from "./lib/databox";
import { rList } from './lib/tools';

interface PersonAddress {
    street: string;
    town: string;
}

interface Person {
    id: string;
    name: string;
    lastName: string;
    address?: PersonAddress
}

interface runEditPersonDialogConfig {
    okBtnText?: string,
    deleteBtn?: boolean,
    check?: (lastname: string) => string,
}

let editAdressDialog = (addr: PersonAddress) => {
    return centralModal(
        (sys: ModalHelper<PersonAddress, 'ok' | 'cancel'>) => {
            return <>
                <Modal.Body>
                    <Alert variant="warning" ref={sys.errorDescRef()}></Alert>
                    <Form>
                        <Form.Group>
                            <Form.Label>Street</Form.Label>
                            <CentralInput ref={sys.inputRef('street')} defaultValue={addr.street}></CentralInput>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Stadt</Form.Label>
                            <CentralInput ref={sys.inputRef('town')} defaultValue={addr.town}></CentralInput>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={sys.closeClick('cancel')}>
                        Abbrechen
                    </Button>
                    <Button variant="primary" onClick={sys.closeClick('ok')}>
                        Speichern
                    </Button>
                </Modal.Footer>
            </>;
        });
};


let runEditPersonDialog = (name: Person, cfg: runEditPersonDialogConfig) => {
    return centralModal(
        (sys: ModalHelper<Person, 'ok' | 'cancel'|'delete'>) => {
            return <>
                <Modal.Body>
                    <Alert variant="warning" ref={sys.errorDescRef()}></Alert>
                    <Form>
                        <Form.Group>
                            <Form.Label>Nachname</Form.Label>
                            <CentralInput ref={sys.inputRef('lastName')} defaultValue={name.lastName}></CentralInput>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Vorname</Form.Label>
                            <CentralInput ref={sys.inputRef('name')} defaultValue={name.name}></CentralInput>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" hidden={!cfg.deleteBtn} className="float-left" onClick={sys.closeClick('delete')}>
                        Delete
                    </Button>
                    <Button variant="secondary" onClick={sys.closeClick('cancel')}>
                        Abbrechen
                    </Button>
                    <Button variant="primary" onClick={sys.closeClick('ok', async (p) => {
                        if (p.lastName == '') return 'Nachname fehlt';
                        if (p.name == '') return 'Name fehlt';
                        if (cfg.check) {
                            let res = cfg.check(p.lastName);
                            if (res !== '') return res;
                        }
                        return ''
                    })}>
                        {cfg.okBtnText || 'Speichern'}
                    </Button>
                </Modal.Footer>
            </>;
        });
};



interface State {
    contacts: Person[];  // A List of Complex Objects to be edited in this demo
}


export class DataBoxDemo extends React.Component<{}, State> {
    constructor(p: any) {
        super(p);
        this.state = {
            contacts: rList<Person>()
                .add(
                    { name: 'Andreas', lastName: 'Häferer' },
                    { name: 'Silke', lastName: 'Häferer' }
                )
        };
    }

    changeContactsInState(exec: (oldList: Person[]) => Person[]) {
        this.setState(r => {
            return {
                contacts: exec(r.contacts)
            }
        })
    }

    event = async (id: string, what: DataBoxWhats) => {
        let delEl = async () => {
            if ((await centralModal(Central.YesNo('Wirklick löschen', 'Bestätigen', {
                textYes: 'Löschen', variantYes : 'warning'
            }))).result == 'yes') {
                this.changeContactsInState(c => rList(c).remove(id));
            }
        }
        if (what == 'delete') {
            return delEl();
        }
        if (what == 'edit') {
            let person = await runEditPersonDialog(this.state.contacts.filter(e => e.id == id)[0],{check: (lastName) => {
                let match = this.state.contacts.filter(e => e.id !== id && e.lastName == lastName);
                if (match.length > 0) return 'This lastname already exists'
                return '';
            }, deleteBtn: true});
            if (person.result == 'ok') {
                return this.changeContactsInState(c => rList(c).change(id, () => person.data))
            };
            if (person.result == 'delete') {
                return delEl();
            };
        }
        if (what == 'add') {
            let person = await runEditPersonDialog({ name: '', lastName: '', id: '' }, {deleteBtn: false, okBtnText: 'Erstellen', check: (lastName) => {
                let match = this.state.contacts.filter(e => e.lastName == lastName);
                if (match.length > 0) return 'This lastname already exists'
                return '';
            }});
            if (person.result == 'ok') {
                this.changeContactsInState(c => rList(c).add(person.data))
            }
        }
    };
    addAddress = async (id: string) => {
        let np = await editAdressDialog({street: '', town: ''});
        if (np.result == 'ok') {
            this.changeContactsInState(r => {
                return rList(r).change(id, (r) => {
                    return Object.assign(r, {address: np.data})
                })
            })
        }

    }
    changeAddress = async (id: string) => {
        let np = await editAdressDialog(this.state.contacts.filter(e => e.id == id)[0].address!);
        if (np.result == 'ok') {
            this.changeContactsInState(r => {
                return rList(r).change(id, (r) => {
                    return Object.assign(r, {address: np.data})
                })
            })
        }
    } 
    render() {
        return <>
            <DataBox style="hasTable" title="Personen" onAdd={this.event.bind(null, '')}>
                {this.state.contacts.map((contact) =>
                    <DataBox key={contact.id} style="tabled" onEdit={this.event.bind(null, contact.id)} onDelete={this.event.bind(null, contact.id)}>
                        {contact.name}, {contact.lastName}<br/>
                        {contact.address !== undefined ?
                            <DataBox style="inline" onEdit={this.changeAddress.bind(null, contact.id)}>
                                <>{contact.address.street}<br/> {contact.address.town}</>
                            </DataBox>
                        : <>Keine Adresse <DataBox.AddBtn onClick={this.addAddress.bind(null,contact.id)}/> </>}
                    </DataBox>
                )}
            </DataBox>
        </>;
    }
}
