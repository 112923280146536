import React from "react";


export interface StarterProps {
    alternates?: {
        [name: string]: JSX.Element
    }
}

export class Starter extends React.Component<StarterProps> {
    private select = (''+window.location.search).substr(1)
    render () {
        if (this.props.alternates && (this.props as any).alternates[this.select] !== undefined) {
            return (this.props as any).alternates[this.select]
        }
        return this.props.children
    }
}