import React from "react";
import { Button, ButtonGroup, Card } from "react-bootstrap";

import './databox.css';
import CEdit from '@material-ui/icons/EditOutlined';
import CDelete from '@material-ui/icons/DeleteForeverOutlined';
import CAdd from '@material-ui/icons/AddOutlined';


export type DataBoxWhats = 'delete'|'edit'|'add';

export type DataBoxEvent = (what: DataBoxWhats) => void;




export interface DataBoxProps {
    /**
     * ```text
     * normal : no change to Card Style
     * tabled : this box is a row inside a List of Objects 
     * hasTable: this.box contains "tabled" databoxes
     * ```
     * @type {('normal'|'tabled'|'hasTable')}
     * @memberof DataBoxProps
     */
    style?: 'normal'|'tabled'|'hasTable'|'inline',
    /**
     * Optional Card.Header (should not be used for "tabled" boxes)
     *
     * @type {string}
     * @memberof DataBoxProps
     */
    title?: string
    /**
     * provide a Button an raise the function, if clicked
     *
     * @type {DataBoxEvent}
     * @memberof DataBoxProps
     */
    onEdit? : DataBoxEvent
    /**
     *  provide a Button an raise the function, if clicked
     *
     * @type {DataBoxEvent}
     * @memberof DataBoxProps
     */
    onAdd? : DataBoxEvent
    /**
     *  provide a Button an raise the function, if clicked
     *
     * @type {DataBoxEvent}
     * @memberof DataBoxProps
     */
    onDelete?: DataBoxEvent
}




/**
 * ```text
 * A Databox is a visual Card Representing an "editable" content,
 * it provides predefined Edit Element (EDIT, ADD, DELETE)
 * to be used.
 * 
 * also provides styles (tabled, hasTable) to defined a List/EDIT Interface for Lists of Objects
 * ```
 * @export
 * @class DataBox
 * @extends {React.Component<DataBoxProps>}
 */
export class DataBox extends React.Component<DataBoxProps> {
    static AddBtn: React.FC<{onClick: () => void}>=  (p) => <Button className="dataBoxBtn" onClick={p.onClick} variant="outline-secondary" size="sm"><CAdd/></Button>
    static EditBtn: React.FC<{onClick: () => void}>=  (p) => <Button className="dataBoxBtn" onClick={p.onClick} variant="outline-secondary" size="sm"><CEdit/></Button>
    static DeleteBtn: React.FC<{onClick: () => void}>=  (p) => <Button className="dataBoxBtn" onClick={p.onClick} variant="outline-danger" size="sm"><CDelete/></Button>
    render () {
        return <Card className={"dbBox"+' '+(this.props.style == undefined ? 'normal' : this.props.style)}> 
            {this.props.title ?<Card.Header><Card.Title>{this.props.title}</Card.Title></Card.Header> : null}
            <Card.Body className="dbBody">
                <div className="dbControls">
                    <ButtonGroup size="sm">
                        {
                            this.props.onDelete ? 
                                <DataBox.DeleteBtn onClick={this.props.onDelete.bind(null, 'delete')} />
                            : null
                        }
                        {
                            this.props.onEdit ? 
                                <DataBox.EditBtn  onClick={this.props.onEdit.bind(null, 'edit')}/>
                            : null
                        }
                        {
                            this.props.onAdd ? 
                                <DataBox.AddBtn onClick={this.props.onAdd.bind(null, 'add')}/>
                            : null
                        }
                    </ButtonGroup>
                </div>
                {this.props.children}
            </Card.Body>
        </Card>
    }
}

