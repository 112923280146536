import React, { createRef, RefObject } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { CentralInput } from "./centralInput";

let _central: Central | undefined;
let _awaitCenterRes: (() => void) | undefined = undefined;
let _awaitCenter = new Promise<void>(res => _awaitCenterRes = res);
let _centralFailed = false;

async function central(): Promise<Central> {
    if (_central == undefined) {
        if (_awaitCenterRes !== undefined) await _awaitCenter;
    }
    if (_central == undefined) throw new Error('No Central Found');
    return _central!
} 

async function centralExecute<A>(fnc: (p: Central) => Promise<A>): Promise<A> {
    if (_central == undefined) {
        if (_centralFailed) throw new Error('Central failed to initialize (No Instance?)')
        if (_awaitCenterRes !== undefined) {
            let timer = setTimeout(() => {
                console.error('Waited for 500ms for central, maybe no <Central/> is active in site ?');
                _centralFailed = true;
            } ,500);
            await _awaitCenter;
            clearTimeout(timer);
        }
    }
    if (_central == undefined) throw new Error('No Central Found');
    return fnc(_central)
}


export async function centralModal<Data extends DataObject, Results extends string = ''>(fnc: (fnc: ModalHelper<Data,Results>) => JSX.Element) {
    return centralExecute(c => c.modal<Data, Results>(fnc))
}

export interface CentralProps {

}

export type DefaultResults = 'yes'|'no'|'cancel'


export type DataObject = Object

export interface MBResult<Data extends DataObject = DataObject, Results extends string = DefaultResults> {
    result: Results;
    data: Data
}


interface Box<Results extends string>{
    id: number,
    show: boolean,
    remove: boolean,
    boxResult: Results,
    errorRef: RefObject<HTMLDivElement>,
    children: JSX.Element;
    select : () => void;
    finish?: (res: Results) => void;
}

export interface CentralState {
    boxes: Box<any>[]
}

export interface ModalHelper<Data extends DataObject, Results extends string> {
    closeClick: (val: Results, check?: (data: Data) => Promise<string>) => () => void,
    inputRef: (name: Extract<keyof Data, string>) => React.RefObject<CentralInput> 
    errorDescRef: () => React.RefObject<HTMLDivElement> 
    onSubmit: (val: Results, check?: (data: Data) => Promise<string>) => (event: React.FormEvent<HTMLFormElement>) => void,
    onKeyPress:(val: Results, key: string, check?: (data: Data) => Promise<string>) => (event: React.KeyboardEvent<any>) => void,
}

export interface YesNoOptions {
    variantYes?: string
    variantNo?: string
    textYes?: string
    textNo?: string
}
export interface AskOptions {
    variantOk?: string
    variantCancel?: string
    textOk?: string
    textCancel?: string
}

export class Central extends React.Component<CentralProps, CentralState> {
    static YesNo = (question: string, title: string= 'Frage', acfg: YesNoOptions = {}) => {
        let cfg = Object.assign ({
            variantNo: 'secondary', 
            variantYes: 'primary',
            textYes: 'Ja',
            textNo: 'Nein'
        } as YesNoOptions, acfg)
        return (sys: ModalHelper<{},'yes'|'no'>) => {
            return <>
                <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {question}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={cfg.variantYes} onClick={sys.closeClick('yes')}>
                        {cfg.textYes}
                    </Button>
                    <Button variant={cfg.variantNo} onClick={sys.closeClick('no')}>
                        {cfg.textNo}
                    </Button>
                </Modal.Footer>
            </>
        }
    }
    static Ask = (value: string, desc: string, title: string= 'Frage', acfg: AskOptions = {}) => {
        let cfg = Object.assign ({
            variantCancel: 'secondary', 
            variantOk: 'primary',
            textCancel: 'Abbruch',
            textOk: 'Ok'
        } as AskOptions, acfg)
        return (sys: ModalHelper<{value: string},'ok'|'cancel'>) => {
            return <>
                <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>{desc}</Form.Label>
                            <CentralInput ref={sys.inputRef('value')} defaultValue={value}></CentralInput>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={cfg.variantCancel} onClick={sys.closeClick('cancel')}>
                        {cfg.textCancel}
                    </Button>
                    <Button variant={cfg.variantOk} onClick={sys.closeClick('ok')}>
                        {cfg.textOk}
                    </Button>
                </Modal.Footer>
            </>
        }
    }
    static AskText = (value: string, desc: string, title: string= 'Frage', acfg: AskOptions = {}) => {
        let cfg = Object.assign ({
            variantCancel: 'secondary', 
            variantOk: 'primary',
            textCancel: 'Abbruch',
            textOk: 'Ok'
        } as AskOptions, acfg)
        return (sys: ModalHelper<{value: string},'ok'|'cancel'>) => {
            return <>
                <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>{desc}</Form.Label>
                            <CentralInput type="textarea" ref={sys.inputRef('value')} defaultValue={value}></CentralInput>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={cfg.variantCancel} onClick={sys.closeClick('cancel')}>
                        {cfg.textCancel}
                    </Button>
                    <Button variant={cfg.variantOk} onClick={sys.closeClick('ok')}>
                        {cfg.textOk}
                    </Button>
                </Modal.Footer>
            </>
        }
    }
    static Info = (info: string, title: string= 'Information', buttonText: string = 'Ok') => {
        return (sys: ModalHelper<{},'ok'>) => {
            return <>
                <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {info}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={sys.closeClick('ok')}>
                        {buttonText}
                    </Button>
                </Modal.Footer>
            </>
        }
    }
    private done: boolean = false;
    private boxId = Date.now();
    modal<Data extends DataObject, Results extends string = DefaultResults>(add: (fnc: ModalHelper<Data,Results>) => JSX.Element) {
        let refs: {ref: React.RefObject<CentralInput>, name: keyof Data}[] = [];
        let errorRef = React.createRef<HTMLDivElement>();
        function getCurrentRefs(): Data{
            let data: Data = {} as Data;
            refs.map(e => { 
                if (e.ref.current) (data as any)[e.name] = e.ref.current.getCurrentValue()
            }) 
            return data;  
        }
        return new Promise<MBResult<Data, Results>>(res => {
            let id = this.boxId++;
            let checkit = async function (check: (data: Data)=> Promise<string>): Promise<boolean>  {
                let checkResult = await check(getCurrentRefs());
                if (checkResult !== '') {
                    if (errorRef.current) {
                        errorRef.current.innerText = checkResult;
                        errorRef.current.style.display = 'block';
                    }
                    return false
                }
                return true
            }
            let nb = add({
                closeClick: (val: Results, check?: (data: Data)=> Promise<string>) => {
                    return async () => {
                        if (check !== undefined) {
                            if (!(await checkit(check))) return;
                        }
                        this.hideBox(id, val)
                    }
                },
                onSubmit: (val: Results, check?: (data: Data)=> Promise<string>) => {
                    return async (ev) => {
                        console.log('ExecuteSubmit')
                        ev.preventDefault();
                        if (check !== undefined) {
                            if (!(await checkit(check))) return;
                        }
                        this.hideBox(id, val)
                    }
                },
                onKeyPress: (val: Results, key: string, check?: (data: Data)=> Promise<string>) => {
                    return async (ev) => {
                        console.log(ev.key);
                        if (ev.key !== key ) return;
                        ev.preventDefault();
                        if (check !== undefined) {
                            if (!(await checkit(check))) return;
                        }
                        this.hideBox(id, val)
                    }
                },
                inputRef: (name: keyof Data) => {
                    let exists = refs.filter(e => e.name == name)[0];
                    if (exists) return exists.ref
                    let b = {ref: createRef<CentralInput>(), name};
                    refs.push(b);
                    return b.ref 
                },
                errorDescRef: () => {
                    return errorRef
                }
            });
            this.setState(r => {
                let boxes: Box<any>[] = ([] as Box<any>[]).concat(r.boxes, [{
                    children: nb,
                    finish: (done: Results) => {
                        
                        res({result: done,data: getCurrentRefs() })
                    },
                    errorRef,
                    select : () => {
                        for (let r of refs) {
                            if (r.ref.current) {
                                console.log('Set Focus on', r.ref.current)
                                r.ref.current.setFocus();
                                return;
                            }
                        }
                    },
                    remove: false,
                    boxResult: 'cancel',
                    id: id,
                    show: true,
                }]);
                return {
                    boxes
                }
            })
        })
    }

    componentDidMount() {
        _central = this;
        if (_awaitCenterRes) _awaitCenterRes();
    }
    componentWillUnmount() {
        _central = undefined;
        this.done = true;
    }
    private hideBox = (id: number, result: string) => {
        console.log('hiding', id)
        this.setState(r => {
            let b: Box<any>[] = r.boxes.map(e => {
                if (e.id == id) {
                    return Object.assign({},e, {boxResult: result, show: false});
                };
                return Object.assign({},e);
            });
            return {
                boxes: b
            }
        });
    }
    private closeBox = (id: number, result?: string) => {
        console.log('Closing', id)
        this.setState(r => {
            let b: Box<any>[] = r.boxes.map(e => {
                if (e.id == id) {
                    if (e.finish !== undefined) e.finish(result ? result : e.boxResult);
                    e.finish = undefined;
                    return undefined
                };
                return e;
            }).filter(e => e !== undefined) as Box<any>[];
            return {
                boxes: b
            }
        });
    }
    constructor(p: CentralProps) {
        super(p);
        this.state = {
            boxes: [],
        }
    }
    private entered = (id: number) => {
        this.state.boxes.map(e => {
            if (e.id == id) {
                e.select();
                if (e.errorRef.current) {
                    e.errorRef.current.style.display= 'none';
                }
            }
        })
    } 


    render() {
        if (this.done) return <div>Central Beendet</div>;
        console.log('RenderBoxes', this.state.boxes)
        return <div>
            {this.state.boxes.map(e => {
                return <Modal show={e.show} onEntering={this.entered.bind(null, e.id)}  onHide={this.hideBox.bind(null, e.id, 'cancel')} onExited={this.closeBox.bind(null, e.id)} key={e.id} >
                        {e.children}
                </Modal>
            })}
        </div>
    }
}