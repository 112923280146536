import React from "react";

import './logger.css'

export class gLogger {
    private static ids: {[name: string]: number} = {}
    constructor(private root: string = '') {

    }
    private gLog<P>(type: 'warn' | 'error' | 'info', st: P, hint?: string): P {
        let log = typeof st === 'object' ? JSON.stringify(st) : '' + st;
        if (hint) log = hint + ': ' + log;
        if (this.root !== '') log = this.root+' '+log;
        switch (type) {
            case 'warn':
                console.warn('>', type, this.root, st)
                break;
            case 'error':
                console.error('>', type, this.root, st)
                break;
            case 'info':
                console.info('>', type, this.root, st)
                break;
            default:
                console.log('>', type, this.root, st)
                break;
        }
        Logger.addtoLog(log);
        return st
    }
    warn : <P>(st: P, hint?: string) => P  = (st, hint) => {
        this.gLog('warn', st, hint);
        return st;
    }
    info : <P>(st: P, hint?: string) => P = (st,hint) => {
        this.gLog('info', st, hint);
        return st;
    }
    error: <P>(st: P, hint?: string) => P = (st,hint) => {
        this.gLog('error', st, hint);
        return st;
    }
    childObject (type: string) : gLogger {
        if (gLogger.ids[type] == undefined) gLogger.ids[type] = 0;
        let id = ++gLogger.ids[type];
        let name = type+'['+id+']'
        return new gLogger(this.root !== '' ? this.root+'/'+name : name);
    }
    child (part: string) : gLogger {
        return new gLogger(this.root !== '' ? this.root+'/'+part : part);
    }
}

export const gLog = new gLogger();

// export function gLog<P>(st: P, hint?: string): P {
//     let log = typeof st === 'object' ? JSON.stringify(st) : '' + st;
//     if (hint) log = hint + ': ' + log;
//     console.log('>', st)
//     Logger.addtoLog(log);
//     return st
// }

export interface LoggerProps {

}

export interface LoggerState {
    visible: boolean
    loglines: {
        id: number,
        line: string,
    }[]
}

export class Logger extends React.Component<LoggerProps, LoggerState> {
    private static ref: Logger[] = [];
    private static unref: string[] = [];
    static addtoLog(st: string) {
        if (Logger.ref.length == 0) return Logger.unref.push(st);
        for (let log of Logger.ref) log.log(st);
        return;
    }
    private static setViewer(ref: Logger) {
        if (Logger.ref.indexOf(ref) < 0) {
            Logger.ref.push(ref);
            if (Logger.ref.length == 1 && Logger.unref.length > 0) {
                for (let st of Logger.unref) {
                    ref.log(st);
                }
            } else {
                if (Logger.ref.length > 1) {
                    console.log('WARNING', 'more then one LogViewer Available');
                }
            }
        }
    }
    private static removeViewer(ref: Logger) {
        let p = Logger.ref.indexOf(ref);
        if (p >= 0) Logger.ref.splice(p, 1)
    }
    componentDidMount() {
        Logger.setViewer(this);
        let ref: NodeJS.Timeout = setInterval(() => {
            if (this.done) return clearInterval(ref);
            if (this.logs.length == 0) return;
            let logs = this.logs; this.logs = [];
            this.setState((prev) => {
                let np = logs.concat(prev.loglines.map(e => Object.assign({}, e)))
                if (np.length > 200) np.splice(200);
                return {
                    loglines: np
                }
            })
        }, 200)
    }
    componentWillUnmount() {
        this.done = true;
        Logger.removeViewer(this);
    }
    public id: number = 1;
    private logs: {
        id: number,
        line: string,
    }[] = [];
    private done: boolean = false;
    public log(st: string) {
        this.logs.push({ id: this.id++, line: st })
    }
    constructor(p: LoggerProps) {
        super(p)
        this.state = {
            loglines: [],
            visible: false,
        }
    }
    toggle = () => {
        this.setState(r => {
            return {
                visible: !r.visible
            }
        })
    }
    render() {
        return <>
            {this.state.visible ?
                <div className="clLogger">
                    <hr />
                    {this.state.loglines.map(e => <div className="clLogLine" key={e.id}>{e.line}</div>)}
                    <hr />
                    <a className="clLogger hide" onClick={this.toggle}>hide log...</a>
                </div> : <a className="clLogger show" onClick={this.toggle}>show log...</a>}

        </>
    }
}