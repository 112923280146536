import { TransferWithinAStationSharp } from "@material-ui/icons";
import React from "react"
import { Button, Form, Modal, NavDropdown } from "react-bootstrap"
import { Central, centralModal, ModalHelper } from "../central/central";
import { CentralInput } from "../central/centralInput";

import './filemanager.css'

export interface FileManagerProps<Data extends Object = Object> {
    id: string,
    fileTypeIdentifier: string,
    jsonValid?: (data: Data) => {valid: boolean, addInfo?: string},
    onSave?: () => void,
}

export interface FileManagerState {
    saveName: string,
    unsavedData?: string,
}

interface FileData {
    name: string;
    addInfo?: string;
    rawdata: any;
    json: any;
    use: boolean;
}


export class FileManager<Data extends Object = Object> extends React.Component<FileManagerProps<Data>, FileManagerState> {

    constructor(p: FileManagerProps) {
        super(p)
        this.state = { saveName: '' }
    }

    getFileDialog(existing: FileData[]) {
        return (sys: ModalHelper<{ savename: string, existing: string }, 'ok'>) => {
            return <>
                <Modal.Header closeButton>
                    <Modal.Title>Daten laden</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Die Daten werden <b>lokal im Browser</b> gespeichert. Bitte geben
                        Sie diesen Daten einen Name.
                    </p>
                    <Form.Group>
                        <Form.Label>Lokaler Speichername</Form.Label>
                        <CentralInput ref={sys.inputRef('savename')} defaultValue={''}
                            onValueChange={(value) => {
                                if (sys.inputRef('existing').current?.getCurrentValue() !== value) {
                                    if (existing.filter(e => e.name == value).length > 0) {
                                        sys.inputRef('existing').current?.setCurrentValue(value)
                                    } else {
                                        sys.inputRef('existing').current?.setCurrentValue('')
                                    }
                                }
                            }}

                        ></CentralInput>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Lokal vorhandene Daten</Form.Label>
                        <CentralInput
                            type="select"
                            map={
                                ([{ value: '', text: 'neu erstellen' }]).concat(
                                    existing.map(e => {
                                        return {
                                            value: e.name,
                                            text: e.name + (e.addInfo ? ' '+e.addInfo: '')
                                        }
                                    }
                                    ))}
                            ref={sys.inputRef('existing')}
                            defaultValue={''}
                            onValueChange={(value) => {
                                if (value !== '') {
                                    sys.inputRef('savename').current?.setCurrentValue(value)
                                }
                            }}
                        >

                        </CentralInput>
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={sys.closeClick('ok', async (data) => {
                        if (data.savename == '') return 'kann nicht leer sein'
                        await centralModal(Central.Info('Wenn Sie die Daten des Browsers löschen, sind die Informationen verloren!','Hinweis'))
                        return ''
                    })}>
                        Ok
                    </Button>
                </Modal.Footer>
            </>
        }
    }

    setSaveData(data: Data, saveNow: boolean = false) {
        this.setState({ unsavedData: JSON.stringify(data) }, () => {
            if (saveNow) this.save();
        });
    }

    save = () => {
        if (!this.state.unsavedData) {
            if (this.props.onSave) this.props.onSave();
            return;
        }
        window.localStorage.setItem(this.props.fileTypeIdentifier+this.state.saveName, this.state.unsavedData);
        this.setState({ unsavedData: undefined })
    }

    async getFileData(): Promise<{ err?: string, data: Data|undefined }> {
        let existing: FileData[] = [];
        for (let i = 0; i < window.localStorage.length; i++) {
            let name = '' + window.localStorage.key(i);
            if (!name.startsWith(this.props.fileTypeIdentifier)) continue;
            name = name.slice(this.props.fileTypeIdentifier.length);
            existing.push({
                use: true,
                name,
                rawdata: window.localStorage.getItem('' + window.localStorage.key(i)),
                json: undefined
            })
        };
        this.setState({ saveName: 'noname' })

        existing = existing
            .filter(e =>
                e.rawdata !== null
                && e.name !== 'null'
                && e.rawdata
                && e.rawdata[0] == '{')
            .map(data => {
                try {
                    return {
                        name: data.name,
                        rawdata: data.rawdata,
                        json: JSON.parse(data.rawdata)
                    } as FileData
                } catch (e) {
                    console.log('ParseError', e.message, data)
                    return Object.assign(e, { json: undefined }) as FileData
                }
            })
            .map(e => {
                let valid = e.json;
                if (this.props.jsonValid) {
                    let data = this.props.jsonValid(e.json);
                    valid = data.valid
                    e.addInfo = data.addInfo
                } else {
                    valid = valid && e.json.start !== undefined
                }
                e.use = valid;
                return e;
            }
            ).filter ( e => e.use)

            ;

        try {
            let answer = await centralModal(this.getFileDialog(existing));
            if (answer.result == 'ok') {
                let ex = existing.filter(e => e.name == answer.data.savename)[0];
                this.setState({ saveName: answer.data.savename })
                return {
                    data: ex !== undefined ? ex.json : undefined
                }
            }
        } catch (e) {
            centralModal(Central.Info(e.message, 'Fehler'))
        }
        return { err: 'unknonw error', data: undefined };
    }
    render() {
        return <NavDropdown title={
            <>{'[' + this.state.saveName + '] '} {this.state.unsavedData !== undefined ? <span className="fmUnsaved">*</span> : ''}</>
        } id={this.props.id}>
            <NavDropdown.Item onClick={this.save}  >Speichern</NavDropdown.Item>
        </NavDropdown>

    }

}