import { AssignmentReturned, CropDin } from "@material-ui/icons";
import { throws } from "assert";
import React from "react";
import { Breadcrumb, Card, CardColumns, CardDeck, CardGroup, Col, Container, Row } from "react-bootstrap";
import { threadId } from "worker_threads";
import { Central, centralModal } from "../central/central";






export interface HomePageProps<Pages extends string> {
    side: HomePageSide<Pages>,
    children: {
        [name in Pages]: React.ReactNode
    },
    selectSubElement? : (id: string|undefined) => void
}

export interface State {
    blocked: boolean,
    currentSide: string,
    blockInfoText?: string,
}

export interface HomePageSide<Pages> {
    title: string,
    desc?: string,
    id: string,
    click?: () => void,
    content?: Pages,
    subPages?: HomePageSide<Pages>[]
}

export interface SideWay {
    id: string,
    title: string,
}

type GetSideInfoAnswer<Pages extends string> = undefined | {
    p: HomePageSide<Pages>;
    way: SideWay[];
};

export class HomePage<Pages extends string> extends React.Component<HomePageProps<Pages>, State> {
    constructor(p: HomePageProps<Pages>) {
        super(p);
        this.state = {
            currentSide: 'root',
            blocked: false,
        };
    }
    private getSideInfo(id: string, current: HomePageSide<Pages> = this.props.side, way: SideWay[] = [{id: this.props.side.id, title: this.props.side.title }]): GetSideInfoAnswer<Pages> {
        if (current.id == id) {
            return { p: current, way }
        } else {
            if (current.subPages) {
                for (let w of current.subPages) {
                    let back = this.getSideInfo(id, w, ([] as SideWay[]).concat(way, [{ id: w.id, title: w.title }]))
                    if (back !== undefined) return back;
                }
                return undefined;
            }

        }
    }

    public setBlock (block: boolean = true, blockInfoText = 'Please Close Subelements first') {
        this.setState({blocked: block});
        if (block) {
            this.setState({blockInfoText})
        } else {
            this.setState({blockInfoText: undefined})
        }
    }

    
    private selectCard = (id: string) => {

        let p = this.getSideInfo(id);
        if (p == undefined) return;
        if (p.p.click) {
            p.p.click();
            return;
        }
        if (this.state.blocked) {
            centralModal(Central.Info(this.state.blockInfoText || 'blockiert'));
            return
        };
        if (p.p.subPages ||p.p.content) {
            if (this.props.selectSubElement) this.props.selectSubElement(p.p.content)
            this.setState({currentSide: id})
            return;
        } else {

        }
    }

    render() {
        let p = this.getSideInfo(this.state.currentSide);
        return <>
            <Container>
                    <Row>
                        <Col md={12}>
                            <Breadcrumb>
                                {p?.way.map(e => <Breadcrumb.Item key={e.id} active={e.id == undefined} onClick={this.selectCard.bind(null, e.id)}>{e.title} </Breadcrumb.Item>)}
                                {this.state.blocked && this.state.blockInfoText ? ' *('+this.state.blockInfoText+')' : null}
                            </Breadcrumb>
                        </Col>
                    </Row>

                {
                    p && p.p.subPages ? <>
                        <Row>
                            <Col md={12}>
                                <CardColumns>
                                    {p.p.subPages.map(p => <Card key={p.id} onClick={this.selectCard.bind(null, p.id)}>
                                        <Card.Body>
                                            <h5>{p.title}</h5>
                                            {p.desc? p.desc : null}
                                        </Card.Body>
                                    </Card>)}
                                </CardColumns>
                            </Col>
                        </Row>

                    </> : null
                }
                {
                    p && p.p.content && (this.props.children as any)[p.p.content] ? <Row>
                        <Col md={12}>
                            {(this.props.children as any)[p.p.content]}
                        </Col>
                    </Row> : null

                }
            </Container>

        </>
    }

   
}
