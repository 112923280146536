import React, { createRef } from "react";
import { Nav, Navbar, NavLink } from "react-bootstrap";
import { FileManager } from "../..";
import { DataList, ListElement } from "./datalist";



export interface LocalData {
    isDataList: boolean,
    lastSaved: number,
    elements: ListElement[]
}





export class DataListApp extends React.Component {
    private fileManager = createRef<FileManager<LocalData>>();
    private datalist = createRef<DataList>();
    save = () => {
        if (!this.fileManager.current) return;
        if (!this.datalist.current) return;
        this.fileManager.current.setSaveData({ isDataList: true, elements: this.datalist.current.getElements(), lastSaved: Date.now() })
    }


    async componentDidMount() {
        if (this.fileManager.current) {
            let data = await this.fileManager.current.getFileData();
            if (!data.err && data.data !== undefined && this.datalist.current) {
                this.datalist.current.setElement(data.data.elements);
            } else {
                if (data.data == undefined && this.datalist.current) {
                    if (this.fileManager.current) {
                        this.fileManager.current.setSaveData({ isDataList: true, elements: [], lastSaved: Date.now() })
                    }
                    this.datalist.current.setElement([]);
                }
            }
        }
    }

    makeBackup = () => {
        if (!this.datalist.current) return;
        let data = JSON.stringify(this.datalist.current.getElements(), undefined, ' ');
        let uri = 'application/json;base64,' + btoa(data);
        var link = document.createElement("a");
        link.download = 'backup' + (new Date()).toISOString().replace(/[\-\:\.\;]+/g, '_') + '.json';
        link.href = 'data:'+uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }


    render() {
        return <>
            <Navbar bg="light" expand="lg" className="hide_on_print">
                <Navbar.Brand href="#home">Zeitenübersicht</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <FileManager<LocalData> jsonValid={(data) => {
                            console.log('Valid', data)
                            let valid = data.isDataList !== undefined;
                            if (valid) return { valid, addInfo: '' + (data.lastSaved !== undefined ? (new Date(data.lastSaved)).toISOString() : '') }
                            return { valid: false }
                        }} ref={this.fileManager} id="base-nav-dropdown" fileTypeIdentifier="ttDataList" />
                        <NavLink onClick={this.makeBackup}>Backup</NavLink>

                    </Nav>
                    {/* <Nav>
                <Nav.Link onClick={this.import}>Import</Nav.Link>
            </Nav> */}
                </Navbar.Collapse>
            </Navbar>
            <DataList ref={this.datalist} hasChanged={this.save} />
        </>
    }
}




