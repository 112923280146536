import { BottomNavigation } from "@material-ui/core";
import { lightBlue } from "@material-ui/core/colors";
import { Mouse, SettingsBackupRestore, TrackChangesOutlined } from "@material-ui/icons";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Central, centralModal, ModalHelper } from "../../central/central";
import { CentralInput } from "../../central/centralInput";






import './escale.css'



export interface Props {


}

export interface Line {
    level: number,
    text: string,
    desc?: string,

    mode: 'level' | 'load_l' | 'load_r' | 'load_km' | 'cons',
}

export interface State {
    level: number,
    battSize: number,
    per100km: number,
    lines: Line[],
    loadStarted: number,
    loadStartLevel: number,
    debug: string,
}

function asTimeSt(min: number) {
    let hour = Math.floor(min / 60);
    let sec = min - hour * 60;
    return hour + ':' + (sec < 10 ? '0' : '') + sec
}

export type SavedValues = 'es_level' | 'es_startlevel' | 'es_started';

export class EScale extends React.Component<Props, State> {
    private refSVG = React.createRef<SVGSVGElement>();
    constructor(p: Props) {
        super(p);
        this.state = {
            battSize: 17.5,

            per100km: 13,
            level: this.getSavedValue('es_level', 50),
            loadStartLevel: this.getSavedValue('es_startlevel', 0),
            loadStarted: this.getSavedValue('es_started', 0),
            lines: [],
            debug: '...'
        }
    }
    private done?: NodeJS.Timeout;

    getSavedValue<A>(st: SavedValues, def: A): A {
        if (window.localStorage.getItem(st) == null) return def
        let val = window.localStorage.getItem(st);
        return JSON.parse('' + val)
    }
    setSavedValue(st: SavedValues, val: any) {
        window.localStorage.setItem(st, JSON.parse(val))
    }

    calculateLoad(mode: 'load_l' | 'load_r', level: number, loadLevel: number, maxLevel: number, consump: number, add: (p: Line) => void) {
        let left = level;
        let p = maxLevel / 100;
        let lv = loadLevel / 12;
        let percPer5Min = lv / p;
        let kmPer5Min = lv / (consump / 100);
        let time = 0;
        let km = 0;
        let lastDispProz = left
        while (left <= 95) {
            time += 5;
            km += kmPer5Min
            left += percPer5Min;
            if (left > 95) break;
            if (left - lastDispProz < 10) continue;
            add({ level: left, mode, text: '' + asTimeSt(Math.round(time)) });
            if (mode == 'load_l') {
    	            add({ level: left, mode: 'load_km', text: '+'+Math.round(km) + 'km', });
            }
            lastDispProz = left;
        }
    }

    calculateRange(level: number, add: (p: Line) => void) {
        let sizeKm = 25;
        let left = level;
        let perKm = (this.state.per100km / this.state.battSize);
        let km = 0;
        while (true) {
            if (left < 5) break;
            if ((left - (sizeKm * perKm)) > 5) {
                left = left - (sizeKm * perKm);
                km += sizeKm
                add({ mode: 'cons', level: left, text: km + 'km' })
            } else {
                if (sizeKm == 25) {
                    sizeKm = 15; continue
                }
                if (sizeKm == 15) {
                    sizeKm = 10; continue
                }
                if (sizeKm == 10) {
                    sizeKm = 5; continue
                }
                break;
            }
        }
    }

    update = (level?: number) => {
        console.log('Iu')
        let lv = level ? level : this.state.level;
        let np: Line[] = [];
        np.push({ mode: 'level', level: lv, text: '' + Math.round(lv) + '%' + (
            this.state.loadStarted > 0 ? 
               ' '+ asTimeSt(Math.round((Date.now() - this.state.loadStarted) / 1000 / 60))
            : ''
        ) });
        this.calculateRange(lv, (p) => np.push(p));
        this.calculateLoad('load_l', lv, 22, this.state.battSize, this.state.per100km, (p) => np.push(p));
        this.calculateLoad('load_r', lv, 11, this.state.battSize, this.state.per100km, (p) => np.push(p));
        this.setState({ lines: np, level: lv }, () => {
            this.setSavedValue('es_level', lv)
        });

    };
    componentWillUnmount() {
        if (this.done) clearInterval(this.done);
    }
    componentDidMount() {
        this.update();
        this.done = setInterval(() => this.update(), 5000);
    }

    transform(x: number, y: number) {
        if (!this.refSVG.current) return { x: x, y: y };
        let ctm = this.refSVG.current.getCTM();
        let pp = this.refSVG.current.createSVGPoint();
        pp.x = x;
        pp.y = y;
        const svgP = pp.matrixTransform(ctm!.inverse());
        this.setState({
            debug: JSON.stringify({
                y: y,
                p: svgP.y,
                ctm: [ctm?.a, ctm?.b, ctm?.c, ctm?.d, ctm?.flipX, ctm?.flipY],
                ct: this.refSVG.current.clientTop,
                st: this.refSVG.current.scrollTop
            })
        });

        return {
            x: svgP.x,
            y: svgP.y
        }
    }

    moveSvg = (event: React.TouchEvent<SVGTextElement>) => {
        if (this.state.loadStarted != 0) return;

        let p = this.transform(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
        let perc = 100 - (p.y / 1000) * 100;
        if (perc > 0 && perc <= 100) {
            this.update(perc)
        };
    }
    clickSvg = (event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
        let p = this.transform(event.clientX, event.clientY);
        let perc = 100 - (p.y / 1000) * 100;
        this.update(perc)
    }

    clickLoad = async () => {
        if (this.state.loadStarted != 0) {
            let answer = await centralModal((sys: ModalHelper<{loadLevel: string}, 'ok' | 'cancel' | 'step'>) => {
                return <>
                    <Modal.Body>
                        <p>
                            Laden jetzt beenden ?
                        </p>
                        <Form>
                            <Form.Group>
                                <Form.Label>Neuer Batteriestand</Form.Label>
                                <CentralInput ref={sys.inputRef('loadLevel')} defaultValue={''}></CentralInput>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={sys.closeClick('cancel')}>
                            Abbrechen
                        </Button>
                        <Button variant="primary" onClick={sys.closeClick('step', async (d) => {
                            let val = parseFloat(d.loadLevel);
                            if ((''+val) == d.loadLevel && (
                                (val > 0 && val <= 100)
                            )) return '';
                            return 'Fehler'
                        })}>
                            Step
                        </Button>
                        <Button variant="primary" onClick={sys.closeClick('ok', async (d) => {
                            let val = parseFloat(d.loadLevel);
                            if ((''+val) == d.loadLevel && (
                                (val > 0 && val <= 100)
                            )) return '';
                            return 'Fehler'
                        })}>
                            Laden Beenden 
                        </Button>
                    </Modal.Footer>
                </>;
            });
            if (answer.result == 'ok') {
                let vx      = parseFloat(answer.data.loadLevel);
                let diffp   = vx - this.state.loadStartLevel;
                let minutes = (Date.now() - this.state.loadStarted) / 1000 / 60;
                let info = diffp / minutes;
                await centralModal(Central.Info(Math.round(info*10) / 10+ ' %/min'));
                this.setState({loadStarted : 0, loadStartLevel : this.state.level}, () => {
                    this.setSavedValue('es_started', 0)
                    this.setSavedValue('es_startlevel', 0)
                    this.update(parseInt(answer.data.loadLevel));
                })
            };
            if (answer.result == 'step') {
                let vx      = parseFloat(answer.data.loadLevel);
                let diffp   = vx - this.state.loadStartLevel;
                let minutes = (Date.now() - this.state.loadStarted) / 1000 / 60;
                let info = diffp / minutes;

                let csP = ((this.state.battSize / 100) * info)*60;


                await centralModal(Central.Info(Math.round(info*10) / 10+ ' %/min '+(Math.round(csP*10)/10)+'kwh/h'));
            };
        } else {
            let answer = await centralModal(Central.YesNo('Laden Starten', 'Ladeanalyse'));
            if (answer.result == 'yes') {
                this.setState({loadStarted : Date.now(), loadStartLevel : this.state.level}, () => {
                    this.setSavedValue('es_started', this.state.loadStarted)
                    this.setSavedValue('es_startlevel', this.state.loadStartLevel)

                })
            }
            this.update();
        }
    }

    render() {
        let pw = 500;
        let wx = [900, 800, 700, 600, 500, 400, 300, 200, 100]
        return <div>
            <svg ref={this.refSVG} className="es_svgplane" height="100%" preserveAspectRatio="none" width="100%" viewBox={"0 0 " + (pw) + " 1000"}>
                <defs>
                    <linearGradient id="borderGradient" x2={"50%"} y2={"0%"} x1={"50%"} y1={"100%"} >
                        <stop offset="10%" stop-color="#f44336" />
                        <stop offset="30%" stop-color="#4caf50" />
                        <stop offset="80%" stop-color="#4caf50" />
                        <stop offset="85%" stop-color="#4caf50" />
                        <stop offset="100%" stop-color="#4caf50" />
                    </linearGradient>
                    <linearGradient id="beamGradient"  x1={"0%"} y1={"0%"} x2={"100%"} y2={"0%"}>
                        <stop offset="0%" stop-color="rgb(255, 255, 255)" stop-opacity="0" />
                        <stop offset="15%" stop-color="rgb(249, 255, 0)" stop-opacity="0.25" />
                        <stop offset="50%" stop-color="rgb(255, 0, 0)" stop-opacity="0.5" />
                        <stop offset="85%" stop-color="rgb(249, 255, 0)" stop-opacity="0.25" />
                        <stop offset="100%" stop-color="rgb(255, 255, 255)" stop-opacity="0" />
                        </linearGradient>
                        <linearGradient id="wayGradient"  x1={"0%"} y1={"0%"} x2={"100%"} y2={"0%"}>
                        <stop offset="0%" stop-color="#0f5d9b" stop-opacity="0" />
                        <stop offset="15%" stop-color="#0f5d9b" stop-opacity="0.5" />
                        <stop offset="50%" stop-color="#0f5d9b" stop-opacity="0.5" />
                        <stop offset="85%" stop-color="#0f5d9b" stop-opacity="0.5" />
                        <stop offset="100%" stop-color="#0f5d9b" stop-opacity="0" />
                        </linearGradient>
                </defs>
                <rect x={0} y={0} width={pw} height={1000} className="es_border">
                </rect>
                {wx.map(e => <React.Fragment key={e + 1}>
                    <line x1={0} y1={e} x2={50} y2={e} className="es_percinfo" />
                    <line x1={pw} y1={e} x2={pw - 50} y2={e} className="es_percinfo" />
                    <text text-anchor="start" alignment-baseline="top" x={5}
                        y={e - 4} className="es_text_per" >
                        {Math.floor(100 - (e / 10)) + '%'}
                    </text>
                    <text text-anchor="end" alignment-baseline="top" x={pw}
                        y={e - 4} className="es_text_per es_range" >
                        {Math.floor(
                            ((this.state.battSize / this.state.per100km) * 100) * ((1000 - e) / 1000)
                        ) + 'km'}
                    </text>

                </React.Fragment>)}

                {
                    this.state.lines.map((e, ix) => {
                        if (e.mode == 'level') {
                            let epl = pw / 4; let epr =  pw / 4 + pw / 2; let epw = 25;
                            let bars = ((100 - e.level) / 100) * 1000;
                            return <>
                            <rect className="es_curr_bar"
                                x={pw / 2 - 150} y={bars} width={300} height={1000 - bars }>
                            </rect>
                            <rect className="es_curr_barload"
                                x={epl-epw} y={0} width={2*epw} height={bars}>
                            </rect>
                            <rect className="es_curr_barload"
                                x={epr-epw} y={0} width={2*epw} height={bars}>
                            </rect>
                            <text x={epl} y={40} textAnchor="middle" alignment-Baseline="bottom" className="es_text_pw" > 22kw

                            </text>
                            <text x={epr} y={40} textAnchor="middle" alignment-Baseline="bottom" className="es_text_pw" > 11kw

                            </text>
                            <line className="es_line es_line_current"
                                x1={0}
                                y1={((100 - e.level) / 100) * 1000}
                                x2={pw}
                                y2={((100 - e.level) / 100) * 1000} >

                            </line>
                            <text onTouchMove={this.moveSvg} text-anchor="middle" alignment-baseline="central" x={pw / 2}
                                y={bars} className="es_text es_text_current" >{e.text}</text>
                        </>
                        };
                        if (e.mode == 'load_km') return <>
                        <line className="es_line"
                            x1={0}
                            y1={((100 - e.level) / 100) * 1000}
                            x2={pw}
                            y2={((100 - e.level) / 100) * 1000} >

                        </line>

                            <text text-anchor="middle" alignment-baseline="central" x={pw / 2}
                                y={((100 - e.level) / 100) * 1000} className="es_text_load_km" >{e.text}</text>
                        </>
                        if (e.mode == 'load_l') return <>
                            <text text-anchor="middle" alignment-baseline="central" x={pw / 4}
                                y={((100 - e.level) / 100) * 1000} className="es_text" >{e.text}</text>
                            {e.desc ? <text className="es_text_desc" y={((100 - e.level) / 100) * 1000 + 50} x={pw / 4} text-anchor="middle" alignment-baseline="central">
                                {e.desc}
                            </text> : null}
                        </>
                        if (e.mode == 'load_r') return <>
                            <text text-anchor="middle" alignment-baseline="central" x={pw / 4 + pw / 2}
                                y={((100 - e.level) / 100) * 1000} className="es_text" >{e.text}</text>
                            {e.desc ? <text className="es_text_desc" y={((100 - e.level) / 100) * 1000 + 50} x={pw / 4 + pw / 2} text-anchor="middle" alignment-baseline="central">
                                {e.desc}
                            </text> : null}
                        </>
                        if (e.mode == 'cons') return <><line className="es_line"
                            x1={0}
                            y1={((100 - e.level) / 100) * 1000}
                            x2={pw}
                            y2={((100 - e.level) / 100) * 1000} >

                        </line>
                            <text text-anchor="middle" alignment-baseline="central" x={pw / 2}
                                y={((100 - e.level) / 100) * 1000} className="es_text" >{e.text}</text>
                        </>
                    })
                }
            </svg>
            {/* <div className="debuginfo">{this.state.debug}</div> */}
            <div className="es_manager">
                {
                    this.state.loadStarted == 0 ?
                    <Button variant={"primary"} onClick={this.clickLoad}>Laden</Button>: 
                    <Button variant={"warning"} onClick={this.clickLoad}>Beenden</Button>
                }
            
            </div>
        </div>
    }
}