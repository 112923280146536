import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactDOM from "react-dom";
import { Central } from "./lib";
import { Starter } from "./lib/starter";
import { DataBoxDemo } from "./databoxdemo";

import 'bootstrap/dist/css/bootstrap.css';
import { CentralDemo } from "./centraldemo";
import { HomePageDemo } from "./homepagedemo";
import { DataList } from "./lib/prototyping/datalist/datalist";
import { DataListApp } from "./lib/prototyping/datalist/datalistapp";
import { EScale } from "./lib/prototyping/escale/escale";


ReactDOM.render(<div>
    <Starter alternates={{
        demo: <h1>Hi, another</h1>,
        databox: <>
            {(() => {
                return <Container>
                    <Row>
                        <Col md={6}>
                            <DataBoxDemo />
                        </Col>
                    </Row>
                </Container>

            })()}
        </>,
        central: <>
            {(() => {
                return <Container>
                    <Row>
                        <Col md={6}>
                            <CentralDemo />
                        </Col>
                    </Row>
                </Container>

            })()}
        </>,
        homepage: <>
            {(() => {
                return <Container >
                    <Row>
                        <Col md={12} className="justify-content-md-center">
                            <HomePageDemo />
                        </Col>
                    </Row>
                </Container>

            })()}
        </>,
        escale: <>
        {(() => {
            return <Container >
                <Row>
                    <Col md={12} className="justify-content-md-center">
                        <EScale />
                    </Col>
                </Row>
            </Container>

        })()}
    </>,
    timelist: <>
        {(() => {
            return <Container >
                <Row>
                    <Col md={12} className="justify-content-md-center">
                            <DataListApp/>
                    </Col>
                </Row>
            </Container>

        })()}
        </>



    }}>
        <p>
            <h3>React Extenstion Demo Starter</h3>
            Please Choose one of the Test SubElements
            <ul>
                <li>
                    <a href="?databox">Databox</a>
                </li>
                <li>
                    <a href="?central">Central MessageBoxing</a>
                </li>
                <li>
                    <a href="?homepage">HomePageControl</a>
                </li>
            </ul>
        </p>
    </Starter>
    <Central />
</div >, document.getElementById("root"));