
export async function waitFor(ms: number) {return new Promise(res => setTimeout(res, ms))};


export function cloneDeep<A> (p: A) : A {
    if (p === undefined || p === null ) return p;
    let tp = typeof p;
    if (tp !== 'object') return p;
    if (Array.isArray(p)) {
        return ((p as any[]).map(x => {
            return cloneDeep<any>(x) 
        })) as unknown as A
    }
    let back : {[name: string]: any} = Object.assign({}, p);
    Object.keys(back).map(key =>{
        back[key] = cloneDeep(back[key] as any)
    })
    return back as A;
}


export interface ReactListElement {
    id?: string
}

export function cloneObjectList<P extends ReactListElement>(p: P[]): P[] {
    return p.map(e => Object.assign({},e))
}

export function cloneList<P extends any>(p: P[]): P[] {
    return p.map(e => {
        if (typeof e === 'object') return Object.assign({},e);
        if (Array.isArray(e)) return ([] as any).concat(e)
        return e;
    })
}



export async function setInState<S extends Object,  A extends keyof S = keyof S>(a: React.Component<{},S>, attr: A, ex: (old: S[A]) => S[A]) {
    return new Promise<void>(res => {
            a.setState(r => {
                let back: any = Object.assign({}, r);
                back[attr] = ex(back[attr])
                return back;
            }, res)
    })
}

let listId = 1;

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export function rList<P extends ReactListElement>(orgList?: P[]) {
    let back: P[] = cloneObjectList(orgList == undefined ? []: orgList);
    let root = {
        remove: (id: string) => {
            back = back.filter(e => e.id !== id);
            return back
        },
        change: (id: string, n: (e: P) => P) => {
            return back.map(e => {
                if (e.id == id) return Object.assign({},n(e), {id: e.id})
                return e;
            })

        },
        add: (...eList: Optional<P,'id'>[]) => {
            for (let e of eList) {
                if (e.id == undefined || e.id == '') e.id = ''+listId++;
                back.push(e as P)
            }
            return back;
        },
    }
    return root;
}


export function rArray<P extends any>(orgList?: P[]) {
    let back: P[] = cloneList(orgList == undefined ? []: orgList);
    let root = {
        remove: (f : (p: P) => boolean) => {
            back = back.filter(e => f(e));
            return back
        },
        change: (f : (p: P) => boolean, nv: P) => {
            return back.map(e => {
                if (f(e)) return nv
                return e;
            })

        },
        add: (...eList: P[]) => {
            for (let e of eList) {
                back.push(e as P)
            }
            return back;
        },
    }
    return root;
}


export function unpackObject (pack: string): any {
    const boundary = '--questtree';
    let start = pack.indexOf(boundary);
    let end = pack.indexOf(boundary+'--');
    if (start < 0 || end < 0) throw new Error('Boundary not found');
    let dpack = pack.substring(start, end+boundary.length+2);
    let dlines = dpack.split(/\r{0,1}\n/);
    let dstart = dlines.indexOf(boundary);
    let dend   = dlines.indexOf(boundary+'--');
    if (dstart < 0 ||dend < 0) throw new Error('BoundaryIn not found');
    let content = dlines.slice(dstart+1, dend)
        .map(e => e.replace(/^\s+/,'').replace(/\s+$/,''));
    let sep = content.indexOf('');
    if (sep < 1) throw new Error('Header Seperator not found');
    let header = content.slice(0,sep); 
    let base64 = content.slice(sep+1).join('');
    let hds : {[name: string]: string} = {};
    header.map(e => e.match(/^([^\:^\s]+)\s*\:\s*(.+)$/) || [])
        .filter(e => e.length == 3)
        .map(c => {
            hds[c[1].toLocaleLowerCase()] = c[2]
        });
    if (hds['content-transfer-encoding'] !== "base64") throw Error('Encoding in header "'+hds['content-transfer-encoding']+'" not supported')
    if (hds['content-type'] !== "application/json; charset=utf-8") throw Error('ContentType  in header "'+hds['content-type']+'" not supported')
    return JSON.parse(atob(base64));
}

export function packObject (obj: any, name: string = 'unnamed'): string {
    let back: string [] = [];
    back.push('--questtree');
    back.push('X-tribetech-Desc: '+name);
    back.push('Content-Type: application/json; charset=utf-8');
    back.push('Content-Transfer-Encoding: base64');
    back.push('');
    let data = btoa(JSON.stringify(obj, (key,value) => {
        if (key[0] == '_') return undefined;
        return value;
    })).match(/[\s\S]{1,64}/g) || [];
    return back.concat(data,['--questtree--']).join('\r\n');
}
